import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Carousel from "react-slick";
import Button from "components/CustomButtons/Button.jsx";
import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/photogrammetry/photogrammetry_images.png";
import image2 from "assets/img/cases/photogrammetry/photogrammetry_process.jpg";
import image3 from "assets/img/cases/photogrammetry/photogrammetry_final.png";
import image4 from "assets/img/cases/photogrammetry/photogrammetry_detail.jpg";

class PhotogrammetrySection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };    
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Modelling Real World Objects</h2>
              <h4>Constructing a 3D model from a set of ordinary photographs using photogrammetry algorithms</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Pyromann",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                        <iframe title="Finwe Pyromann Demo" frameborder="0" allowfullscreen mozallowfullscreen="true" 
                        webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" 
                        xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share 
                        width="1024" height="600"
                        src="https://sketchfab.com/models/26d80bf56b6f4289ac8c3d06d05b52d9/embed"></iframe> 
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "DeWALT",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe title="Finwe Pyromann Demo" frameborder="0" allowfullscreen mozallowfullscreen="true" 
                        webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" 
                        xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share 
                        width="1024" height="600"
                        src="https://sketchfab.com/models/3c8835fa51534cb699536d401eed4e63/embed"></iframe> 
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Gingerbread House",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe title="Finwe Pyromann Demo" frameborder="0" allowfullscreen mozallowfullscreen="true" 
                        webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" 
                        xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share 
                        width="1024" height="600"
                        src="https://sketchfab.com/models/1deda224130d405392bcfbcb508d1fbc/embed"></iframe> 
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Rock",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                      <iframe title="Finwe Pyromann Demo" frameborder="0" allowfullscreen mozallowfullscreen="true" 
                        webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" 
                        xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share 
                        width="1024" height="600"
                        src="https://sketchfab.com/models/d299252b2827437f80457f67e4df08b4/embed"></iframe> 
                      </div>
                    </div>
                    )
                },
                {
                  tabName: "Photos",
                  tabIcon: "",
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Photogrammetry is a technology that uses photographs to measure and create 3D models of 
                          objects or environments. First, you take a large number of images (often hundreds) using 
                          a DSLR or sometimes a smartphone or a video camera. Second, a photogrammetry algorithm 
                          analyzes each photo to detect common points or features in the images. Using those common 
                          points, the software can calculate the position and distance of each point in 3D space
                          and create a dense point cloud, similar to laser scanners. Third, it can construct a surface 
                          mesh that represents the object or environment. Finally, the mesh can be textured with the 
                          original images to create a realistic 3D model, which you can light, rotate and view from 
                          any angle.
                        </p>
                        <p>
                          Photogrammetry is a very powerful technology that can be used to create 3D models of
                          almost anything. It is often used for modelling unique objects and environments, where 
                          a designed CAD model either does not exist or is not easily available. It is used in many 
                          industries, such as architecture, construction, manufacturing, and entertainment. It is 
                          also used in many scientific fields (archaeology, biology, and geology) and artistic fields 
                          (sculpture, painting, and photography).
                        </p>
                        <p>
                          When a 3D model is needed from a real-world object, photogrammetry is often the best
                          solution. It is a very cost-effective way to create a 3D model, and it is often the
                          only way to create a 3D model of an object that is too large or too complex to be
                          scanned with a 3D scanner. The images can be captured manually by a photographer, 
                          or automatically using a drone and pre-planned flight path (environments), rotating
                          turntable (objects), or a robotic arm (large objects). It is also possible to use a large
                          number of cameras to capture all needed angles at the same time - for example, to
                          quickly create a precise 3D model of a human. High quality video is a fast way to produce
                          a large number of images, and a 360 camera can be used for capturing all directions at
                          the same time. Almost all imaging methods are compatible with photogrammetry.
                        </p>
                        <p>
                          Photogrammetry also has some limitations. The quality of the 3D model depends on the
                          quality of the images. The more images you have, the better the model will be. The
                          images must be taken from different angles. The object must also be stationary during 
                          the image capture process. If the object is moving, the images will not match and the model 
                          will be inaccurate. Partially transparent and reflective surfaces, such as glass or shiny
                          metal, are also difficult to capture with photogrammetry. The model must be coated with 
                          matte paint or evaporating scanning spray to avoid this problem. Calculating the model in
                          good precision requires a lot of computing power: typically a fast GPU, a lot of RAM, and 
                          a few hours of time. The final model may also be very large, which makes it difficult to
                          share and use without decimating the model. The result may contain artefacts, such as
                          holes, cracks, and overlaps that need manual clean-up. Obviously, photogrammetry is not 
                          the best solution for fast pace production lines. Here laser based methods such as LIDAR
                          or 3D camera are a better choice, as they can produce precise point clouds almost immediately 
                          (see our examples of scanning with SICK 3D cameras).
                        </p>
                        <p>
                          Finwe has used photogrammetry for years in many projects. We have created 3D models
                          of different size objects and environments using the manual DSLR process as well as
                          2D and 360 degree cameras attached to sticks, robots, drones, and wheeled vehicles.
                          We have also developed our own algorithms for indoor positioning based on the calculated
                          route of the camera.
                        </p>
                        <p>
                          <b>
                            To learn more about modelling objects or environments with photogrammetry, contact us.
                          </b>
                        </p>
                        <Button
                          round
                          color="success"
                          size="md"
                          href="contact-page/"
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          Contact us
                        </Button>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(PhotogrammetrySection);
