import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import Carousel from "react-slick"
import Card from "components/Card/Card.jsx"
import Video from "components/Video/Video.jsx"

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx"

import image1 from "assets/img/cases/converge/converge1.jpg"
import image2 from "assets/img/cases/converge/converge2.jpg"
import image3 from "assets/img/cases/converge/converge3.jpg"
import image4 from "assets/img/cases/converge/converge4.jpg"
import image5 from "assets/img/cases/converge/converge5.jpg"
import image6 from "assets/img/cases/converge/converge6.jpg"
import image7 from "assets/img/cases/converge/converge7.jpg"
import image8 from "assets/img/cases/converge/converge8.jpg"

import Converge from "assets/img/logos/converge-480x480.png"
import EU from "assets/img/logos/EU-480x480.png"
import Inesctec  from "assets/img/logos/inesctec-480x480.png"
import Eurecom from "assets/img/logos/eurecom-480x480.png"
import Inria from "assets/img/logos/inria-480x480.png"
import UnivOulu from "assets/img/logos/univoulu-480x480.png"

class PaaliSection extends React.Component {
  render() {
    const { classes } = this.props
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    }
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">
            <div className={classes.title}>
              <h2>CONVERGE (EU Horizon)</h2>
              <h4>Tools for Research Infrastructures</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                /*
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://www.youtube-nocookie.com/embed/E02Bqblce7E"
                        videoTitle="Demo"
                        aspectRatio="56.25%"
                      />
                    </div>
                  )
                },
                */
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image6}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image7}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image8}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "Demo",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <iframe
                        className={classes.iframe}
                        src="https://finweltd.github.io/app-babylonjs-converge/c0cc8a06f9bc250a79cc41932ab94492a9a164e3/index.html"
                        title="CONVERGE Demo"
                        allow="picture-in-picture"
                        frameBorder="0"
                        width="1024" height="600" 
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      />
                    </div>
                  ),
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          "The main objective of the CONVERGE project is the development of an innovative toolset 
                          aligned with the motto “view-to-communicate and communicate-to-view”. This toolset is a 
                          world-first and consists of vision-aided large intelligent surfaces, vision-aided fixed 
                          and mobile base stations, a vision-radio simulator and 3D environment modeler, and machine 
                          learning algorithms for multimodal data including radio signals, video streams, RF sensing,
                          and traffic traces."
                        </p>
                        <p>
                          Finwe has developed digital twins (interactive 3D models) of radio measurement chambers 
                          and other research facilities using different approaches. We have modeled a few chambers 
                          using tradional 3D modeling tools and captured the same chambers as 360° photos, allowing
                          easily switching between modeled and captured environments. In co-operation with FinCloud Ltd.,
                          we have laser scanned multiple chambers and research facilities using state-of-the-art 
                          Leica scanners and created point cloud and mesh representations of the environments.
                        </p>
                        <p>
                          We have also developed a web-based 3D viewer application that can be used to view the
                          digital twins in a web browser. The application supports 3D models, 360° photos, and point
                          clouds, and allows users to navigate in the models, measure distances, and view additional
                          information about the objects in the models. Moreover, the application works in modern
                          XR headsets, allowing users to view the models in virtual reality in an immersive way.
                        </p>
                        <p>
                          The digital twins contain various visualizations of radio signals, such as signal
                          strength maps and signal paths. Different capabilities of 3D engines have been utilized
                          to visualize the radio signals in the models, such as particle systems and point clouds.
                          The visualizations are intended to help different stakeholders to understand the radio 
                          propagation phenomena by simplifying complex data into easily understandable visualizations.
                        </p>
                        <p>
                          Robot arms also play a role in the project. We are working with ROS and robot arms that can be
                          used e.g. to move equipment in the chambers. The robot arms can be controlled via the web-based
                          3D environment, which allows the user move the robot arm safely outside the chamber.
                        </p>
                        <p>
                          <b>
                            The visualizations and the robot arm control can be easily expanded to other use cases.
                            Contact us to learn more or try it out.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={
                              (classes.marginAuto, classes.beforeTitle)
                            }
                          >
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={Converge}
                              alt="CONVERGE Project"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={EU}
                              alt="Co-funded by the European Union"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={
                              (classes.marginAuto, classes.beforeTitle)
                            }
                          >
                            <h4>In co-operation:</h4>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={UnivOulu}
                              alt="University of Oulu"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={Inesctec}
                              alt="InescTec"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={Eurecom}
                              alt="Eurecom"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={Inria}
                              alt="Inria"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  ),
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <p className={classes.textCenter}>
                        <a href="https://converge-project.eu/">
                          CONVERGE Project
                        </a>
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(defaultSectionStyle)(PaaliSection)
